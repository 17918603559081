*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: linear-gradient(to bottom, #fff, #eee);
  background-color: #eee;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  font-family: 'Poppins', Arial, sans-serif;
  color: #111;
  font-weight: 300;
}

h1 {
  background: #111;
  color: white;
  margin-top: 0;
  padding: 5px 0;
  font-weight: 700;
  text-align: center;
  margin: 0 0 10px 0;
}

.nba-app {
  padding: 0;
  width: 375px;
  margin: 0;
}

.ranker {
  padding: 0 10px;
}

.player {
  padding: 2px 0;
}

.player-inner {
  cursor: pointer;
  font-size: 20px;
  border: 1px solid #eee;
  background-color: rgba(255, 255, 255, 1);
  height: 50px;
  width: 355px;
  line-height: 50px;
  padding: 0 10px 0 0;
  overflow: hidden;
  overflow-wrap: normal;
  position: relative;
}

.player span.image {
  width: 45px;
  height: 100%;
  float: left;
}

.player span.image svg {
  margin-left: 5px;
}

.player .rank {
  right: 0;
  position: absolute;
  color: white;
  margin-left: 0.5em;
  z-index: 1;
  padding: 0;
  float: left;
  width: 30px;
  text-align: center;
  height: 48px;
}

.player span.rank-incorrect {
  background: rgb(200,0,0);
}

.player span.rank-correct {
  background: rgb(0,100,0,0.7);
}

.player span.rank-start {
  background: rgb(100,100,100);
}

.player img {
  height: 50px;
  width: 34px;
}

.player .correct {
  background-color: rgba(180, 255, 180, 1);
}

.player .stats {
  padding: 0 10px;
  background: rgb(0,100,0,0.7);
  width: 110px;
  text-align: right;
}

.player .stats_underline {
  width: 110px;
  border-bottom: 8px solid rgb(0,100,0);
  position: absolute;
  right: 0;
  bottom: 0;
}

.results-div {
  float: left;
  width: 40px;
  display: block;
  clear: both;
}

.player-list {
  float: left;
  display: block;
}

.status {
  font-size: 20px;
  display: block;
  clear: both;
  line-height: 1.2;
  margin-bottom: 0px;
  padding-top: 10px;
  text-align: center;
  font-weight: 700;
  white-space: pre-line;
}

.buttons {
  clear: both;
  display: block;
  padding: 10px 0 0 0;
  font-size: 20px;
}

button {
  font-size: 30px;
  border-radius: 6px;
  background-color: #555;
  color: #eee;
  height: 50px;
  margin-bottom: 10px;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 500;
}

.generate-new {
  padding: 0 10px;
}

.generate-new select {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: 'Poppins', Arial, sans-serif;
  color: #111;
  text-align: left;
  width: 170px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.generate-new span {
  width: 150px;
  display: block;
  float: left;
  height: 32px;
  line-height: 32px;
}

button.check-it {
  width: 355px;
  background-color: rgb(0,100,0);
}

.generate-new .reset {
  width: 355px;
  margin-top: 10px;
  background-color: rgb(0,0,100);
}

.welcome {
  font-size: 20px;
  display: block;
  clear: both;
  padding: 0;
  margin: 0 0 10px 0;
  line-height: 1.2;
}

.stat_reset {
  float: right;
}

.params div {
  margin-bottom: 4px;
}

.share-link {
  text-align: center;
  margin-top: 10px;
}

.share-link button {
  border-radius: 6px;
  color: #eee;
  height: 40px;
  font-size: 20px;
  line-height: 32px;
}

.share-link button.share-button {
  background-color: rgb(0,0,100);
  margin-right: 10px;
}

.footer {
  text-align: center;
  border-top: 1px solid #111;
  margin-top: 10px;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: rgb(0,100,0);
}

button:disabled, button[disabled] {
  background-color: #555;
  color: #ccc;
}

.current-stats {
  font-size: 30px;
  padding: 0 10px;
}

.current-stats .row {
  clear: both;
}

.current-stats .today {
  font-size: 20px;
  font-weight: 700;
}

.current-stats .label {
  width: 110px;
  float: left;
}

.current-stats .value {
  width: 50px;
  float: left;
}

.controls {
  clear: both;
  padding: 0 10px;
}

.controls button {
  width: 75px;
  margin-right: 10px;
  font-size: 16px;
}

.controls .wide button {
  width: 160px;
  margin-right: 10px;
}

.controls button.green {
  background-color: rgb(0,100,0);
}

.controls button.blue {
  background-color: rgb(0,0,100);
}

.controls button.red {
  background-color: rgb(100,0,0);
}


